
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.users.edit_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <form @submit.prevent="updateUsers" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_name" type="text" :label="$store.getters.language.data.users.user_name" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="['admin','user']" v-model="users.user_role" dense  filled outlined item-text="user_role"
                            item-value="user_role" :return-object="false" :label="$store.getters.language.data.users.user_role">
                        </v-select>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"  type="submit">{{$store.getters.language.data.users.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/users.request.js'
    export default {
        data() {
            return {
                users: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneUsers()
        },
        methods: {
            getOneUsers() {
                this.loading = true
                requests.getOneUsers(this.id).then(r =>{
                    if (r.status == 200) {
                        this.users = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateUsers() {
                this.loading = true
                requests.updateUsers(this.id,this.users).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.update_success,
                            color: 'success'
                        }
                        let index = this.$store.getters.users_list.findIndex(obj => obj.user_id == this.id);
                        this.$store.state.users_list[index] = r.data.new_data;
                        this.loading = false
                        this.$router.push('/users-list');
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    